@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-Thin.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-Thin.woff) format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-ThinItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-ThinItalic.woff) format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-ExtraLight.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-ExtraLight.woff) format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-ExtraLightItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-ExtraLightItalic.woff) format('woff');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-Light.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-Light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-LightItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-LightItalic.woff) format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-Regular.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-Regular.woff) format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-RegularItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-RegularItalic.woff) format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-Medium.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-Medium.woff) format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-MediumItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-MediumItalic.woff) format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-SemiBold.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-SemiBold.woff) format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-SemiBoldItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-SemiBoldItalic.woff) format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-ExtraBold.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-ExtraBold.woff) format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-Bold.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-Bold.woff) format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-ExtraBoldItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-ExtraBoldItalic.woff) format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-BoldItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-BoldItalic.woff) format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-Black.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-Black.woff) format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Metropolis';
	src: url(../../assets/fonts/Metropolis-BlackItalic.woff2) format('woff2'),
	url(../../assets/fonts/Metropolis-BlackItalic.woff) format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}