/* variables.css */

:root {
    --primary-color: #F9B224;
    --secondary-color: #6c757d;
    --background-color: #f8f9fa;
    --text-color: #212529;
    --primary-light-color: #fcf3e3;
}


.contact-button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 5px; /* Space between buttons */
    transition: background-color 0.3s;
}

.contact-button:hover {
    background-color: #f0f0f0; /* Change background on hover */
}
