:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #F9B224;
  --bs-secondary: #565656;
  --bs-success: #2DA94F;
  --bs-info: #0dcaf0;
  --bs-warning: #E7C075;
  --bs-danger: #F27373;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 29,161,242;
  --bs-secondary-rgb: 86,86,86;
  --bs-success-rgb: 45,169,79;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 231,192,117;
  --bs-danger-rgb: 242,115,115;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 28,28,28;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg-rgb: 255,255,255;
  --bs-font-sans-serif: "Metropolis",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-line-height: 1.25;
  --bs-body-color: #1c1c1c;
  --bs-body-bg: #f3f3f3;
  background: #F3F3F3;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-navbar-width: 225px;
  --bs-logo-height: 100px;
  --bs-navbar-margin-top: 75px;
}

@media (min-width: 1550px) {
  :root {
    --bs-body-font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  :root {
    --bs-navbar-width: 250px;
    --bs-logo-height: 150px;
  }
}

@media (min-width: 1550px) {
  :root {
    --bs-navbar-width: 300px;
    --bs-logo-height: 160px;
  }
}

.text-muted {
  color: #565656!important;
}

.link-warning {
  color: var(--bs-warning)!important;
}

.link-warning:focus, .link-warning:hover {
  color: #c9a050!important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.navbar-brand img {
  width: 200px;
}

.remember-me .form-check-input {
  float: none;
  margin-left: 0em;
}

.form-check-input:checked {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn {
  --bs-btn-padding-y: 0.65rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 1rem;
  --bs-btn-active-border-color: transparent;
}

@media (min-width: 1200px) {
  .btn {
    --bs-btn-font-size: 1.25rem;
  }
}

.btn-group-sm > .btn, .btn-sm {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 0.75rem;
}

.btn-group-lg > .btn, .btn-lg {
  --bs-btn-padding-y: 1.25rem;
  --bs-btn-border-radius: 1rem;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-warning);
  --bs-btn-border-color: var(--bs-warning);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c9a050;
  --bs-btn-hover-border-color: #c9a050;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c9a050;
  --bs-btn-active-border-color: #c9a050;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c9a050;
  --bs-btn-disabled-border-color: var(--bs-warning);
}

.form-control {
  padding: 0.65rem 1.25rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #565656;
  border-radius: 1rem;
}

.form-control-lg {
  padding: 0.9rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.input-secondary {
  background-color: var(--bs-secondary);
  border-color: #565656;
}

.input-secondary::-webkit-input-placeholder {
  color: #c4c4c4;
}

.input-secondary:-ms-input-placeholder {
  color: #c4c4c4;
}

.input-secondary::placeholder {
  color: #c4c4c4;
}

.left-side-menu {
  width: var(--bs-navbar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  background-color: #1c1c1c;
  display: none;
  transition: all .1s ease-out;
  margin-top: var(--bs-navbar-margin-top);
  padding-top: 1rem;
}

.left-side-menu.show {
  display: block;
}

@media (min-width: 1200px) {
  .left-side-menu {
    width: var(--bs-navbar-width);
    display: block;
    padding-top: var(--bs-logo-height)!important;
    pointer-events: auto;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .content-page {
    margin-left: var(--bs-navbar-width);
  }
}

.logo-box {
  width: var(--bs-navbar-width);
  height: var(--bs-logo-height);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.75rem;
  --bs-dropdown-padding-y: 0.75rem;
  --bs-dropdown-spacer: 0.5rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-border-color: #fff;
  --bs-dropdown-border-radius: 0rem;
  --bs-dropdown-box-shadow: box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.1);
  --bs-dropdown-link-active-color: #383838;
  --bs-dropdown-link-active-bg: #e9ecef;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
}

@media (min-width: 1200px) {
  .dropdown-menu {
    --bs-dropdown-font-size: 1.25rem;
  }
}

.avatar-dropdown .rounded-circle {
  width: 40px;
  height: 40px;
}

@media (min-width: 1550px) {
  .avatar-dropdown .rounded-circle {
    width: 60px;
    height: 60px;
  }
}

.avatar-dropdown .dropdown-toggle::after {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 0;
  vertical-align: initial;
  content: "";
  border: 0;
  background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.42041 7.37079L13.0704 1.71079C13.1641 1.61783 13.2385 1.50723 13.2893 1.38537C13.3401 1.26351 13.3662 1.1328 13.3662 1.00079C13.3662 0.868781 13.3401 0.738075 13.2893 0.616216C13.2385 0.494356 13.1641 0.383755 13.0704 0.290792C12.8831 0.104542 12.6296 -3.21984e-08 12.3654 -4.37463e-08C12.1012 -5.52942e-08 11.8478 0.104541 11.6604 0.290792L6.66041 5.24079L1.71041 0.290792C1.52305 0.104541 1.2696 -5.2876e-07 1.00541 -5.40308e-07C0.741227 -5.51856e-07 0.487776 0.104541 0.300413 0.290792C0.205925 0.383407 0.130754 0.493852 0.0792579 0.615727C0.0277614 0.737602 0.000963173 0.868485 0.000413851 1.00079C0.000963162 1.1331 0.0277614 1.26398 0.0792579 1.38586C0.130754 1.50773 0.205925 1.61818 0.300413 1.71079L5.95041 7.37079C6.04405 7.4723 6.15771 7.55331 6.28421 7.60871C6.41071 7.66412 6.54731 7.69272 6.68541 7.69272C6.82351 7.69272 6.96012 7.66412 7.08662 7.60871C7.21312 7.55331 7.32677 7.4723 7.42041 7.37079Z' fill='white'/%3E%3C/svg%3E") center / auto no-repeat;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.avatar-position {
  font-size: 0.75rem;
}

.navbar-search {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1;
  background-color: #fff;
  display: none;
  box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
}

.navbar-search.show {
  display: block;
}

@media (min-width: 1200px) {
  .navbar-search {
    position: relative;
    background-color: transparent;
    padding: 0;
    display: block;
    box-shadow: none;
  }
}

.navbar-search .position-absolute {
  right: 2rem;
  top: 50%;
  transform: translateX(0px) translateY(-50%);
  color: #BCBCCB;
}

@media (min-width: 1200px) {
  .navbar-search .position-absolute {
    right: 1rem;
  }
}

.navbar-search input {
  padding-right: 50px;
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .navbar-search input {
    border-color: transparent;
  }
}

@media (min-width: 1550px) {
  .navbar-search input {
    font-size: 1.25rem;
    padding: 0.85rem 1.25rem;
  }
}

.btn-svg {
  width: 46px;
  height: 46px;
  border-radius: 16px;
}

@media (min-width: 1550px) {
  .btn-svg {
    width: 60px;
    height: 60px;
  }
}

.btn-svg:hover path {
  stroke: #000;
}

.btn-light {
  --bs-btn-bg: #F3F3F3;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-disabled-color: #c4c4c4;
  --bs-btn-disabled-bg: #F3F3F3;
}

.bg-wallet {
  --bs-btn-bg: #F3F3F3;
  --bs-btn-border-color: var(--primary-light-color);
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-disabled-color: #c4c4c4;
  --bs-btn-disabled-bg: #F3F3F3;
    background-color: var(--primary-light-color);
    border-radius: 12px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-custom {
  border-bottom: 1px solid #dee2e6;
}

@media (min-width: 1200px) {
  .navbar-custom {
    border-color: transparent;
  }
}

.menu-items {
  --bs-nav-link-padding-x: 1.5rem;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-color: #565656;
  --bs-nav-link-hover-color: #fff;
  --bs-nav-link-disabled-color: #6c757d;
}

.menu-items {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: transparent;
  --bs-nav-tabs-border-radius: 0.5rem;
  --bs-nav-tabs-link-hover-border-color: transparent;
  --bs-nav-tabs-link-active-color: #fff;
  --bs-nav-tabs-link-active-bg: #565656;
  --bs-nav-tabs-link-active-border-color: #565656;
}

.menu-items .nav-link {
  border-radius: var(--bs-nav-tabs-border-radius);
  font-size: 1rem;
  display: flex;
}

@media (min-width: 1550px) {
  .menu-items .nav-link {
    font-size: 1.25rem;
  }
}

@media (min-width: 1400px) {
  .menu-items .text-light {
    font-size: 1.5rem;
  }
}

.menu-items .nav-link.active {
  font-weight: 500;
}

.menu-items .nav-link.active path, .menu-items .nav-link:hover path {
  stroke: #fff;
}

.sidebar-overlay {
  background-color: rgba(50,58,70,.2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: var(--bs-navbar-margin-top);
  display: none;
  z-index: 5;
  transition: all .2s ease-out;
}

.sidebar-overlay.show {
  display: block;
}

[data-bs-toggle=dropdown] {
  color: #565656;
}

[data-bs-toggle=dropdown]:hover {
  color: #000;
}

.text-over-line {
  position: relative;
}

.text-over-line span {
  position: relative;
  color: #565656;
}

.text-over-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: #565656;
}

.social-list a {
  display: inline-block;
}

.social-list img {
  width: 40px;
  height: 40px;
}

@media (min-width: 1200px) {
  .social-list img {
    width: 50px;
    height: 50px;
  }
}

.fw-medium {
  font-weight: 500!important;
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-border-color: #fff;
  --bs-card-border-radius: 1rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg:#fff;
}

.card-header {
  border-bottom: var(--bs-card-border-width) solid #F3F3F3;
}

.stats-icon {
  width: 90px;
  height: 90px;
  background-color: #F3F3F3;
}

@media (min-width: 1550px) {
  .stats-icon {
    width: 100px;
    height: 100px;
  }
}

.stats-icon-warning {
  background-color: var(--bs-warning);
}

.btn-more-dropdown {
  width: 40px;
  height: 40px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='5' viewBox='0 0 20 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z' fill='%231C1C1C'/%3E%3Cpath d='M9.83887 5C11.2196 5 12.3389 3.88071 12.3389 2.5C12.3389 1.11929 11.2196 0 9.83887 0C8.45816 0 7.33887 1.11929 7.33887 2.5C7.33887 3.88071 8.45816 5 9.83887 5Z' fill='%231C1C1C'/%3E%3Cpath d='M17.1772 5C18.558 5 19.6772 3.88071 19.6772 2.5C19.6772 1.11929 18.558 0 17.1772 0C15.7965 0 14.6772 1.11929 14.6772 2.5C14.6772 3.88071 15.7965 5 17.1772 5Z' fill='%231C1C1C'/%3E%3C/svg%3E") center no-repeat;
}

.btn-more-dropdown-sm {
  width: 30px;
  height: 30px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='5' viewBox='0 0 20 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z' fill='%231C1C1C'/%3E%3Cpath d='M9.83887 5C11.2196 5 12.3389 3.88071 12.3389 2.5C12.3389 1.11929 11.2196 0 9.83887 0C8.45816 0 7.33887 1.11929 7.33887 2.5C7.33887 3.88071 8.45816 5 9.83887 5Z' fill='%231C1C1C'/%3E%3Cpath d='M17.1772 5C18.558 5 19.6772 3.88071 19.6772 2.5C19.6772 1.11929 18.558 0 17.1772 0C15.7965 0 14.6772 1.11929 14.6772 2.5C14.6772 3.88071 15.7965 5 17.1772 5Z' fill='%231C1C1C'/%3E%3C/svg%3E") center / auto no-repeat;
}

.btn-more-dropdown.show {
  background-color: #F3F3F3!important;
}

.restaurant-name img {
  width: 75%;
  height: 75%;
}

@media (min-width: 768px) {
  .restaurant-name img {
    width: 65px;
    height: 65px;
  }
}

@media (min-width: 1550px) {
  .restaurant-name img {
    width: 70px;
    height: 70px;
  }
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media (min-width: 576px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .stats-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .stats-grid {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1550px) {
  .stats-grid {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

@media (min-width: 1200px) {
  .content-grid {
    grid-template-columns: 3fr 1fr;
  }
}

.fs-12 {
  font-size: 0.75rem!important;
}

.progress {
  --bs-progress-height: 0.25rem;
}

.content-end-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

@media (min-width: 576px) {
  .content-end-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .content-end-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .content-end-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .content-end-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.status-badge-success path {
  fill: var(--bs-success);
}

.status-badge-warning path {
  fill: var(--bs-warning);
}

.btn-edit:hover path, .btn-delete:hover path {
  fill: #000;
}

.custom-pagination {
  --bs-pagination-padding-x: 1rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 0.75rem;
  --bs-pagination-color: #9E9E9E;
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: 1rem;
  --bs-pagination-hover-color: #9E9E9E;
  --bs-pagination-hover-bg: #F3F3F3;
  --bs-pagination-hover-border-color: #F3F3F3;
  --bs-pagination-focus-color: var(--bs-warning);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: var(--bs-warning);
  --bs-pagination-active-border-color: var(--bs-warning);
  --bs-pagination-disabled-color: #9E9E9E;
  --bs-pagination-disabled-bg: #F3F3F3;
  --bs-pagination-disabled-border-color: #F3F3F3;
}

@media (min-width: 1550px) {
  .custom-pagination {
    --bs-pagination-padding-x: 1.25rem;
    --bs-pagination-padding-y: 1rem;
  }
}

.custom-pagination .page-link {
  border-radius: 1rem;
}

.table th, .table td {
  white-space: nowrap;
}

p, span {
  font-size: 0.75rem;
}

@media (min-width: 1550px) {
  p, span {
    font-size: 1.25rem;
  }
}

@media (min-width:1200px) {
  .h3, h3 {
    font-size: 1.25rem;
  }
}

@media (min-width:1550px) {
  .h3, h3 {
    font-size: 1.75rem;
  }
}

@media (min-width:1200px) {
  .h4, h4 {
    font-size: 1rem;
  }
}

@media (min-width:1550px) {
  .h4, h4 {
    font-size: 1.5rem;
  }
}

@media (min-width:1200px) {
  .h5, h5 {
    font-size: 1rem;
  }
}

@media (min-width:1550px) {
  .h5, h5 {
    font-size: 1.25rem;
  }
}

.restaurant-item {
  border: 1px solid #C4C4C4;
  border-radius: 16px;
}

.restaurant-item .more-dropdown {
  top: 0.5rem;
  right: 0.5rem;
}

@media (min-width: 768px) {
  .restaurant-item .more-dropdown {
    top: 0;
    right: 0;
  }
}

.restaurant-contact p {
  font-size: 0.75rem;
}

@media (min-width: 1550px) {
  .restaurant-contact p {
    font-size: 1.25rem;
  }
}

.user-item {
  border: 1px solid #C4C4C4;
  border-radius: 16px;
}

.user-item hr {
  border-color: #F3F3F3;
  opacity: 1;
}

.user-name img {
  width: 50px;
  height: 50px;
}

@media (min-width: 1550px) {
  .user-name img {
    width: 60px;
    height: 60px;
  }
}

.hotel-item {
  border: 1px solid #C4C4C4;
  border-radius: 16px;
}

.hotel-item img {
  width: 50px;
  height: 50px;
}

.hotel-item hr {
  border-color: #F3F3F3;
  opacity: 1;
}

.badge {
  --bs-badge-padding-x: 1em;
  --bs-badge-padding-y: 0.5em;
  --bs-badge-font-size: 0.625em;
  --bs-badge-font-weight: 400;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.5rem;
}

.bank-details {
  border: 1px solid #C4C4C4;
  border-radius: 16px;
}

@media (min-width: 768px) {
  .bank-details {
    width: 333px;
  }
}

@media (min-width: 1550px) {
  .bank-details {
    width: 533px;
  }
}

.stats-icon-2 {
  width: 70px;
  height: 70px;
}

.current-order-item img {
  width: 60px;
  height: 60px;
}

.current-order-item .order-item-border {
  border-left: 1px solid #F3F3F3;
}

.current-order-item .text-success circle {
  fill: var(--bs-success);
}

.current-order-item .text-warning circle {
  fill: var(--bs-warning);
}

.current-order-item .text-danger circle {
  fill: var(--bs-danger);
}

.img-icon-1 {
  width: 40px;
  height: 40px;
}

@media (min-width: 1550px) {
  .img-icon-1 {
    width: 60px;
    height: 60px;
  }
}

.invoice-sent-item {
  border-bottom: 1px solid #F3F3F3;
}

.invoice-sent-item h3 {
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .invoice-sent-item h3 {
    font-size: 1rem;
  }
}

@media (min-width: 1550px) {
  .invoice-sent-item h3 {
    font-size: 1.25rem;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b85d0;
  --bs-btn-hover-border-color: #0b85d0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-gradient: none;
}

.custom-accordion-1 {
  --bs-accordion-color: #383838;
  --bs-accordion-bg: #fff;
  --bs-accordion-border-color: #fff;
  --bs-accordion-border-radius: 1rem;
  --bs-accordion-btn-padding-x: 1rem;
  --bs-accordion-btn-padding-y: 0.5rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: #fff;
  --bs-accordion-btn-icon-width: 0.75rem;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #fff;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #1c1c1c;
  --bs-accordion-active-bg: #fff;
}

.custom-accordion-1 .accordion-button::after {
  transform: rotate(-90deg);
  margin-left: 1rem;
}

.custom-accordion-1 .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.custom-accordion-1 .badge {
  border-radius: 1rem;
  font-weight: 700;
}

.btn-success {
  --bs-btn-bg: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-bg: #198035;
  --bs-btn-hover-border-color: #198035;
  --bs-btn-active-bg: #198035;
  --bs-btn-active-border-color: #198035;
  --bs-btn-disabled-bg: var(--bs-success);
  --bs-btn-disabled-border-color: var(--bs-success);
}

.btn-outline-success {
  --bs-btn-color: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-bg: var(--bs-success);
  --bs-btn-hover-border-color: var(--bs-success);
  --bs-btn-active-bg: var(--bs-success);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-disabled-color: var(--bs-success);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-success);
}

.btn-outline-success:hover path {
  stroke: #fff;
}

.btn-decline {
  background-color: #F3F3F3!important;
  border-color: #F3F3F3!important;
}

.callout {
  width: 25px;
  height: 20px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 0H5C2.24 0 0 2.23 0 4.98V10.96V11.96C0 14.71 2.24 16.94 5 16.94H6.5C6.77 16.94 7.13 17.12 7.3 17.34L8.8 19.33C9.46 20.21 10.54 20.21 11.2 19.33L12.7 17.34C12.89 17.09 13.19 16.94 13.5 16.94H15C17.76 16.94 20 14.71 20 11.96V4.98C20 2.23 17.76 0 15 0ZM11 11.75H5C4.59 11.75 4.25 11.41 4.25 11C4.25 10.59 4.59 10.25 5 10.25H11C11.41 10.25 11.75 10.59 11.75 11C11.75 11.41 11.41 11.75 11 11.75ZM15 6.75H5C4.59 6.75 4.25 6.41 4.25 6C4.25 5.59 4.59 5.25 5 5.25H15C15.41 5.25 15.75 5.59 15.75 6C15.75 6.41 15.41 6.75 15 6.75Z' fill='%23565656'/%3E%3Crect x='4' y='5' width='12' height='8' fill='%23565656'/%3E%3C/svg%3E") no-repeat;
  background-size: 100% 100%;
  font-weight: 500;
  font-size: 10px;
}

.callout-primary {
  width: 25px;
  height: 20px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 0H5C2.24 0 0 2.23 0 4.98V10.96V11.96C0 14.71 2.24 16.94 5 16.94H6.5C6.77 16.94 7.13 17.12 7.3 17.34L8.8 19.33C9.46 20.21 10.54 20.21 11.2 19.33L12.7 17.34C12.89 17.09 13.19 16.94 13.5 16.94H15C17.76 16.94 20 14.71 20 11.96V4.98C20 2.23 17.76 0 15 0ZM11 11.75H5C4.59 11.75 4.25 11.41 4.25 11C4.25 10.59 4.59 10.25 5 10.25H11C11.41 10.25 11.75 10.59 11.75 11C11.75 11.41 11.41 11.75 11 11.75ZM15 6.75H5C4.59 6.75 4.25 6.41 4.25 6C4.25 5.59 4.59 5.25 5 5.25H15C15.41 5.25 15.75 5.59 15.75 6C15.75 6.41 15.41 6.75 15 6.75Z' fill='%231DA1F2'/%3E%3Crect x='4' y='5' width='12' height='8' fill='%231DA1F2'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  font-weight: 500;
  font-size: 10px;
}

.custom-accordion-2 {
  --bs-accordion-color: #1c1c1c;
  --bs-accordion-border-color: #fff;
  --bs-accordion-border-radius: 0rem;
  --bs-accordion-btn-padding-x: 1rem;
  --bs-accordion-btn-padding-y: 0.75rem;
  --bs-accordion-btn-color: #1c1c1c;
  --bs-accordion-btn-icon-width: 0.75rem;
  --bs-accordion-btn-icon-transform: rotate(0deg);
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231c1c1c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #fff;
  --bs-accordion-btn-focus-box-shadow: 0;
  --bs-accordion-body-padding-x: 1rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #1c1c1c;
  --bs-accordion-active-bg: #fff;
}

.custom-accordion-2 .accordion-button {
  font-weight: 700;
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .custom-accordion-2 .accordion-button {
    font-size: 1.25rem;
  }
}

.custom-accordion-2 .accordion-button::after {
  margin-left: 0;
  display: none;
}

.custom-accordion-2 .accordion-button::before {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  margin-right: 0.75rem;
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  transform: rotate(-90deg);
}

.custom-accordion-2 .accordion-button:not(.collapsed)::before {
  transform: rotate(0deg);
}

.menu-editor-dropdown > .btn {
  --bs-btn-border-radius: 0rem;
}

.menu-editor-dropdown > .btn.show, .menu-editor-dropdown > .btn:active, .menu-editor-dropdown > .btn:hover {
  color: var(--bs-btn-active-color);
  background-color: #F3F3F3;
  border-color: var(--bs-btn-active-border-color);
  transform: rotate(0deg);
}

.files input {
  outline: 2px dashed #D9D9D9;
}

.files input:focus {
  outline: 2px dashed #D9D9D9;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
  border: 1.5px dashed #D9D9D9;
  border-radius: 10px;
}

.files input {
  top: 0;
  left: 0;
  opacity: 0;
}

.files img {
  display: inline-block;
  width: 68px;
}

.form-control.bg-light {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  color: #1c1c1c;
  background-color: #f3f3f3;
  border: 1.5px solid #c4c4c4;
  border-radius: 1rem;
}

@media (min-width: 1200px) {
  .form-control.bg-light {
    font-size: 1rem;
  }
}

@media (min-width: 1550px) {
  .form-control.bg-light {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1.25rem;
    color: #1c1c1c;
    background-color: #f3f3f3;
    border: 1.5px solid #c4c4c4;
    border-radius: 1rem;
  }
}

.form-check-input:checked[type=radio] {
  background-color: #fff;
  border-color: var(--bs-warning);
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23E7C075'/%3e%3c/svg%3e");
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.input-icon .position-absolute {
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon input {
  padding-left: 2.25rem!important;
}

@media (min-width: 1200px) {
  .input-icon input {
    padding-left: 3rem!important;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.form-select.bg-light {
  display: block;
  width: 100%;
  padding: .7rem 2.25rem .7rem .75rem;
  font-size: 1rem;
  color: #1C1C1C;
  background-color: #F3F3F3;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9 1L6.825 5.075C6.34375 5.55625 5.55625 5.55625 5.075 5.075L1 1' stroke='%23C4C4C4' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 10px 5px;
  border: 1px solid #C4C4C4;
  border-radius: 1rem;
}

@media (min-width: 1550px) {
  .form-select.bg-light {
    font-size: 1.25rem;
  }
}

